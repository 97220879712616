import * as React from "react";

import Topic from "../components/topic";

// markup
const ServicePage = () => {
  const post = {
    title: 'Hire Dedicated Mobile App Developers | Hire Mobile App Developers',
    metaDescription: '',
    h1: 'Hire Dedicated Mobile App Developers',
    h1Subtext1: 'The journey to the app store is worth it.',
    h1Subtext2: 'MatteCurve - The best Mobile App development company in India. We are a JavaScript company and have dedicated Mobile App developers for you.',
    aboutSection: {
        title: 'About Mobile App',
        subTitle: 'Build Better Accessibility, Brand Recognition, and improved Customer Engagement with mobile app development.',
        paragraph1: "Smartphones play a significant role in everyone’s life. It is a process of creating software or applications that run on mobile devices, such as smartphones or tablets, is called a mobile app.",
        paragraph2: "These mobile apps give access to sharing information, products, or services online that can be installed on the device, and enabled backend services for data access through APIs. The mobile apps are helping us in making our personal and professional work fast and easy.",
        keyPoints: [
            "Mobile Applications became a popular platform to build web and native apps which give access to sharing information, products, or services online.",
            "We follow an agile approach to develop your product and streamline all the development processes.",
            "Having expertise in Android, iOS, and other cross-platform app solutions and focus on every aspect of the app, including user interface, user experience, visual identity, UI designs, and wire frames.",
            "We provide high-end security to all your data and information that are highly protected by the team.",
            "Our developers have the ability to develop specialized, unique, and technologically advanced mobile apps",
            "Mobile App provides robust and reliable technology on multiple platforms to validate the app feasibility."
        ],
    },
    statsSection: {
        paragraph: "Mobile Applications became a popular platform to build web and native apps. Currently, 581 billion mobile app downloads in the world for different purposes like education, games, business, and so on.  Mobile App gaining a huge market day by day due to their popularity There are many mobile apps developed by companies that use native platforms like Instagram and Skype. Hire Dedicated Mobile App Developers in India from MatteCurve to grow by 4X.",
    },
    benefitsSection: {
        title: "Benefits of Hiring Dedicated Mobile App Developers",
        keyPoints: [
            "Improve Project Outcomes",
            "Cost-effective",
            "No investment On Hardware/Software Requirement",
            "Get Access to Skilled Developers",
            "Agile Approach",
            "Collaborative Engagement",
            "Painless Project Management",
            "Greater focus on business"
        ],
        image: "https://images.unsplash.com/photo-1603404235009-71c1f80e31fc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80",
    },
    ctaTexts: [
        "Hire dedicated Mobile App Developers?",
        "Hire dedicated Mobile App Developers from MatteCurve?",
    ],
    why: {
        title: "Why Choose MatteCurve for Mobile App Development?",
        paragraph: "MatteCurve Technologies is a top-notch Mobile App Development Company with 10+ years of experience in the industry of high-quality, outsourcing. We work with a variety of mobile app platforms like Android, iOS, windows, and cross-platform app development. Our app developers create unique apps based on React, Native that build robust business-centric apps that are performance-oriented as well.",
    }
  };


  return (
    <Topic post={post}></Topic>
  )
}

export default ServicePage;
